import { CallToAction } from './CallToAction'
import { FormattedEntry } from './FormattedEntry'

export enum Spacing {
  Double = 'double',
  Single = 'single'
}

export type AbstractContentSection = {
  backgroundColor?: string
  bottomSpacing?: Spacing
  content: string
  elementId?: string
  fontTheme?: 'light' | 'dark'
  fullBleed?: boolean
  heading: string
  links: FormattedEntry<CallToAction>[]
  subheading: string
  topSpacing?: Spacing
}
