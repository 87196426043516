import React, { FC, ReactNode } from 'react'

import { ContentSection, ImageContentSection } from '@viewlio/types/src/contentful'
import {
  Spacing,
} from '@viewlio/types/src/contentful/AbstractContentSection'
import { ContentWidth, ContentPosition } from '@viewlio/types/src/contentful/ContentSection'
import classNames from 'classnames'

import styles from './EntryLayout.module.scss'

type ContentSectionProps = Pick<
  ContentSection,
  'contentWidth' |
  'contentPosition' |
  'backgroundColor' |
  'fullBleed' |
  'bottomSpacing' |
  'topSpacing' |
  'elementId'
  > & {
  imagePosition?: never
}

type ImageContentSectionProps = Pick<
  ImageContentSection,
  'imagePosition' |
  'backgroundColor' |
  'fullBleed' |
  'bottomSpacing' |
  'topSpacing' |
  'elementId'
  > & {
  contentPosition?: never
  contentWidth?: never
}

type Props = (ContentSectionProps | ImageContentSectionProps) & {
  className?: string
  gridChild?: boolean
  renderEntry(gridChild: boolean): ReactNode
}

export const EntryLayout: FC<Props> = ({
  backgroundColor = 'transparent',
  bottomSpacing = 'single',
  contentPosition,
  contentWidth,
  elementId,
  fullBleed = true,
  gridChild = false,
  imagePosition,
  renderEntry,
  topSpacing = 'single',
}) => {
  const wrapperClasses = classNames(styles.wrapper, {
    [styles.fullBleed]: fullBleed,
    [styles.gridChild]: gridChild,
    [styles.topSingleSpacing]: topSpacing === Spacing.Single,
    [styles.topDoubleSpacing]: topSpacing === Spacing.Double,
    [styles.bottomSingleSpacing]: bottomSpacing === Spacing.Single,
    [styles.bottomDoubleSpacing]: bottomSpacing === Spacing.Double,
  })

  const contentWrapperClasses = classNames(styles.contentWrapper, {
    [styles.shortLine]: contentWidth === ContentWidth.Short,
    [styles.longLine]: contentWidth === ContentWidth.Long,
    [styles.centered]: contentPosition === ContentPosition.Center,
    [styles.gridChild]: gridChild,
  })

  const getContentBackgroundColor = () => {
    // If content section has no image and is a grid child,
    // then its background should be transparent
    if (gridChild && !imagePosition) {
      return 'transparent'
    }

    if (gridChild) {
      return backgroundColor
    }

    if (fullBleed) {
      return 'transparent'
    }

    return backgroundColor
  }

  const getWrapperBackgroundColor = () => {
    // If content section has no image and is a grid child,
    // then its background should be transparent
    if (gridChild && !imagePosition) {
      return 'transparent'
    }

    if (gridChild) {
      return 'transparent'
    }

    if (fullBleed) {
      return backgroundColor
    }

    return 'transparent'
  }

  return (
    <div
      className={wrapperClasses}
      style={{ backgroundColor: getWrapperBackgroundColor() }}
      data-testid='wrapper'
      id={elementId}
    >
      <div
        className={contentWrapperClasses}
        style={{ backgroundColor: getContentBackgroundColor() }}
        data-testid='content-wrapper'
      >
        {renderEntry(gridChild)}
      </div>
    </div>
  )
}
