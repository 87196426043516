import { Entry } from 'contentful'

import { AbstractContentSection } from './AbstractContentSection'

export enum ContentPosition {
  Center = 'center',
  Start = 'start',
}

export enum ContentWidth {
  Long = 'long',
  Short = 'short',
}

export type ContentSection = AbstractContentSection & {
  contentPosition?: ContentPosition
  contentWidth?: ContentWidth
  disclaimerContent: string
  disclaimerHeading: string
  overrideLinksNode?: any
}

export type ContentSectionEntry = Entry<ContentSection>
