import React, { FC } from 'react'

import { ImageContentSection, ContentSection } from '@viewlio/types/src/contentful'
import { ContentPosition } from '@viewlio/types/src/contentful/ContentSection'
import { ImagePosition } from '@viewlio/types/src/contentful/ImageContentSection'
import cx from 'classnames'

import { markdown, MarkdownOptions } from 'utils/markdown'

import { EntryCallToActions } from '../EntryCallToActions'

import styles from './EntryContent.module.scss'

type ImageContentSectionProps = Pick<
  ImageContentSection,
  'content' |
  'heading' |
  'subheading' |
  'imagePosition' |
  'fullBleed' |
  'fontTheme' |
  'links'
> & {
  contentPosition?: never
  disclaimerContent?: never
  disclaimerHeading?: never
}

type ContentSectionProps = Pick<
  ContentSection,
  'content' |
  'heading' |
  'subheading' |
  'contentPosition' |
  'fullBleed' |
  'fontTheme' |
  'links' |
  'disclaimerContent' |
  'disclaimerHeading'
> & {
  imagePosition?: never
}

type Props = (ImageContentSectionProps | ContentSectionProps) & {
  buttonDirection?: 'row' | 'column'
  className?: string
  gridChild?: boolean
  headingClassName?: string
  markdownOptions?: MarkdownOptions
  overrideLinksNode?: React.ReactNode
}

export const EntryContent: FC<Props> = ({
  buttonDirection = 'column',
  content,
  contentPosition,
  disclaimerContent,
  disclaimerHeading,
  fullBleed = true,
  headingClassName,
  imagePosition,
  heading,
  markdownOptions,
  subheading,
  overrideLinksNode = undefined,
  ...props
}) => {
  const classes = cx(styles.container, {
    [styles.light]: props.fontTheme === 'light',
    [styles.gridChild]: props.gridChild,
    [styles.fullBleed]: fullBleed,
    [styles.right]: imagePosition === ImagePosition.Start,
    [styles.left]: imagePosition === ImagePosition.End,
    [styles.centered]: contentPosition === ContentPosition.Center,
    [styles.noImage]: !imagePosition,
  })

  const callToActionsClasses = cx({
    [styles.centered]: contentPosition === ContentPosition.Center,
    [styles.disclaimerPresent]: disclaimerContent || disclaimerHeading,
  })

  return (
    <div
      data-testid='content-container'
      className={classes}
    >
      {subheading && (
        <h3
          className={cx(
            styles.subheading,
            {
              [styles.gridChild]: props.gridChild,
            },
          )}
        >{subheading}</h3>
      )}
      {heading && (
        <h2
          className={cx(
            styles.heading,
            {
              [styles.gridChild]: props.gridChild,
            },
            headingClassName,
          )}
        >{heading}</h2>
      )}
      {content && (
        <div
          className={cx(
            styles.content,
            styles.markdown,
            {
              [styles.gridChild]: props.gridChild,
            },
          )}
          // The body content can contain markup as a string as it will be
          // dangerously rendered onto the page. We need to do this to support
          // Markdown from Contentful.
          dangerouslySetInnerHTML={{
            __html: markdown(content, markdownOptions),
          }}
        />
      )}

      {overrideLinksNode || (
        <EntryCallToActions
          buttonDirection={buttonDirection}
          className={callToActionsClasses}
          {...props}
        />
      )}

      {disclaimerHeading && (
        <h4
          className={styles.disclaimerHeading}
        >{disclaimerHeading}</h4>
      )}

      {disclaimerContent && (
        <div
          className={cx(styles.disclaimerContent, styles.markdown)}
          // The body content can contain markup as a string as it will be
          // dangerously rendered onto the page. We need to do this to support
          // Markdown from Contentful.
          dangerouslySetInnerHTML={{
            __html: markdown(disclaimerContent, markdownOptions),
          }}
        />
      )}
    </div>
  )
}
