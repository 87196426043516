import { Entry, Asset } from 'contentful'

import { AbstractContentSection } from './AbstractContentSection'

export enum ImagePosition {
  End = 'end',
  Removed = 'removed',
  Start = 'start'
}

export type ImageContentSection = AbstractContentSection & {
  altText?: string
  desktopImage: Asset
  imagePosition: ImagePosition
  mobileImage: Asset
}

export type ImageContentSectionEntry = Entry<ImageContentSection>
