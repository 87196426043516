import React, { FC } from 'react'

import { animated } from '@react-spring/web'
import { ImageWithTextEntry as ImageWithTextEntryType } from '@viewlio/types/src/contentful'
import { ImagePosition } from '@viewlio/types/src/contentful/ImageContentSection'
import cx from 'classnames'

import { EntryContent } from 'components/contentful/EntryContent/EntryContent'
import { EntryLayout } from 'components/contentful/EntryLayout/EntryLayout'
import { ImageLink } from 'components/contentful/ImageLink'

import styles from './ImageWithTextEntry.module.scss'
import { useAnimation } from './useAnimation'

export const ImageWithTextEntry: FC<ImageWithTextEntryType> = ({
  buttonDirection = 'column',
  fullBleed = true,
  hasAnimation = false,
  imagePosition = ImagePosition.Start,
  classNames,
  ...props
}) => {
  const {
    maybeContentSpringStyle,
    maybeImageSpringStyle,
    maybeIntersectionObserverRef,
  } = useAnimation(hasAnimation, imagePosition === ImagePosition.End)

  const wrapperClasses = (gridChild: boolean) =>
    cx(
      styles.wrapper,
      {
        [styles.wrapperWithGrid]: gridChild,
      },
      classNames?.wrapperClassName,
    )

  const mediaPositionStyles = cx({
    [styles.endMedia]: imagePosition === ImagePosition.End && !props.gridChild,
  })

  const imageStyles = cx(
    styles.image,
    {
      [styles.fullBleed]: fullBleed && !props.gridChild,
    },
  )

  return (
    <EntryLayout
      {...props}
      key={props.id}
      fullBleed={fullBleed}
      imagePosition={imagePosition}
      renderEntry={(gridChild) => (
        <div
          ref={maybeIntersectionObserverRef}
          className={wrapperClasses(gridChild)}
        >
          <animated.div
            style={maybeImageSpringStyle}
            className={mediaPositionStyles}
          >
            <ImageLink
              imageClass={imageStyles}
              imagePosition={imagePosition}
              {...props}
            />
          </animated.div>
          <animated.div style={maybeContentSpringStyle}>
            <div className={classNames?.contentClassName}>
              <EntryContent
                imagePosition={imagePosition}
                buttonDirection={buttonDirection}
                fullBleed={fullBleed}
                {...props}
              />
            </div>
          </animated.div>
        </div>
      )}
    />
  )
}
