import { config, useChain, useSpring, useSpringRef } from '@react-spring/web'
import { useInView } from 'react-intersection-observer'

export const useAnimation = (hasAnimation = false, isImageOnRight: boolean) => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })

  const contentSpringRef = useSpringRef()
  const imageSpringRef = useSpringRef()

  const contentSpringStyle = useSpring({
    config: config.molasses,
    opacity: inView ? 1 : 0,
    ref: contentSpringRef,
    x: inView ? '0px' : (isImageOnRight ? '-20px' : '20px'),
  })

  const imageSpringStyle = useSpring({
    opacity: inView ? 1 : 0,
    ref: imageSpringRef,
    y: inView ? '0px' : '20px',
  })

  useChain([imageSpringRef, contentSpringRef], [0, .3])

  return {
    maybeContentSpringStyle: hasAnimation ? contentSpringStyle : null,
    maybeImageSpringStyle: hasAnimation ? imageSpringStyle : null,
    maybeIntersectionObserverRef: hasAnimation ? ref : null,
  }
}
