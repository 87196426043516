import React, { FC } from 'react'

import { ImageWithTextEntry as ImageWithTextEntryType } from '@viewlio/types/src/contentful'

import { LocalizedLink } from 'components/common/LocalizedLink'
import { NextGenImage } from 'components/common/NextGenImage'
import styles from 'components/contentful/ImageWithTextEntry/ImageWithTextEntry.module.scss'
import { getAspectRatio } from 'utils/image'

const maxWidths = {
  desktop: 1216,
  mobile: 767,
}

type Props = {
  imageClass?: string
} & ImageWithTextEntryType

const LinkWrapper = ({ children, className, links, overrideImageOnClick }) => {
  if (overrideImageOnClick) {
    // Used, for example, on coupons where we need a JS onClick to trigger
    // geolocation (and not the href link from contentful)
    return (
      <span onClick={overrideImageOnClick} className={className} style={{ cursor: 'pointer' }}>
        {children}
      </span>
    )
  }

  if (Array.isArray(links) && links.length > 0) {
    return (
      <LocalizedLink href={links[0].url} className={className}>
        {children}
      </LocalizedLink>
    )
  }

  return <>{children}</>
}

export const ImageLink: FC<Props> = ({
  aboveTheFold,
  altText,
  imageClass,
  desktopImage,
  imagePosition,
  links,
  mobileImage,
  overrideImageOnClick,
}) => {
  const className = imagePosition ? styles[`${imagePosition}Media`] : ''
  return (
    <LinkWrapper {...{
      className,
      links,
      overrideImageOnClick,
    }}>
      <NextGenImage
        altText={altText}
        aspectRatio={{
          desktop: getAspectRatio(desktopImage),
          mobile: getAspectRatio(mobileImage),
        }}
        desktopImage={desktopImage}
        imageClass={imageClass}
        lazyLoad={!aboveTheFold}
        maxWidths={maxWidths}
        mobileImage={mobileImage}
        wrapperClass={className}
      />
    </LinkWrapper>
  )
}
